<template>
    <div class="shop-settings">
        <div class="page-header">
            <h1>{{ $t('Settings') }} > {{ $t('Content Management') }}</h1>
        </div>
        <div class="page-content">
            <div class="content-header">
                <h2>{{ $t('Content Management') }}</h2>
            </div>
            <div class="form-content">
                <div class="lang-switcher-tab">
                    <el-tabs v-model="currentTab" @tab-click="handleClick">
                        <el-tab-pane label="中文" name="zh">
                            <el-form ref="form" :model="form">
                                <el-form-item label="關於我們" style="max-width: 80%">
                                    <vue-editor v-model="form.aboutUsZh"></vue-editor>
                                </el-form-item>
                                <el-form-item label="送貨資料" style="max-width: 80%">
                                    <vue-editor v-model="form.shippingInfoZh"></vue-editor>
                                </el-form-item>
                                <el-form-item label="常見問題" style="max-width: 80%">
                                    <vue-editor v-model="form.faqZh"></vue-editor>
                                </el-form-item>
                                <el-form-item label="條款細則" style="max-width: 80%">
                                    <vue-editor v-model="form.termZh"></vue-editor>
                                </el-form-item>
                                <el-form-item label="私隱政策" style="max-width: 80%">
                                    <vue-editor v-model="form.privacyPolicyZh"></vue-editor>
                                </el-form-item>
                                <el-form-item class="update-button">
                                    <el-button type="primary" @click="updateSettings">{{ $t('Update') }}</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="English" name="en">
                            <el-form ref="form" :model="form">
                                <el-form-item label="About Us" style="max-width: 80%">
                                    <vue-editor v-model="form.aboutUsEn"></vue-editor>
                                </el-form-item>
                                <el-form-item label="Shipping Info" style="max-width: 80%">
                                    <vue-editor v-model="form.shippingInfoEn"></vue-editor>
                                </el-form-item>
                                <el-form-item label="Faq" style="max-width: 80%">
                                    <vue-editor v-model="form.faqEn"></vue-editor>
                                </el-form-item>
                                <el-form-item label="Terms and conditions" style="max-width: 80%">
                                    <vue-editor v-model="form.termEn"></vue-editor>
                                </el-form-item>
                                <el-form-item label="Privacy Policy" style="max-width: 80%">
                                    <vue-editor v-model="form.privacyPolicyEn"></vue-editor>
                                </el-form-item>
                                <el-form-item class="update-button">
                                    <el-button type="primary" @click="updateSettings">{{ $t('Update') }}</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { VueEditor } from "vue2-editor";
import Common from '@/lib/common';

export default {
    name: 'Content',
    mounted() {
        this.loadSettings();
    },
    components: {
        VueEditor
    },
    data() {
        const form = {
            aboutUsZh: '',
            shippingInfoZh: '',
            faqZh: '',
            termZh: '',
            privacyPolicyZh: '',
            aboutUsEn: '',
            shippingInfoEn: '',
            faqEn: '',
            termEn: '',
            privacyPolicyEn: '',
        };
        return {
            form,
            currentTab: 'zh',
        }
    },
    methods: {
        async loadSettings() {
            try {
                const loginInfo = Common.getLoginInfo();

                //Chinese version
                const aboutUsZh = await Common.loadConfig(this.apiUrl, 'about_us_zh', loginInfo);
                const shippingInfoZh = await Common.loadConfig(this.apiUrl, 'shipping_info_zh', loginInfo);
                const faqZh = await Common.loadConfig(this.apiUrl, 'faq_zh', loginInfo);
                const termZh = await Common.loadConfig(this.apiUrl, 'term_zh', loginInfo);
                const privacyPolicyZh = await Common.loadConfig(this.apiUrl, 'privacy_policy_zh', loginInfo);

                this.form.aboutUsZh = aboutUsZh;
                this.form.shippingInfoZh = shippingInfoZh;
                this.form.faqZh = faqZh;
                this.form.termZh = termZh;
                this.form.privacyPolicyZh = privacyPolicyZh;

                //English version
                const aboutUsEn = await Common.loadConfig(this.apiUrl, 'about_us_en', loginInfo);
                const shippingInfoEn = await Common.loadConfig(this.apiUrl, 'shipping_info_en', loginInfo);
                const faqEn = await Common.loadConfig(this.apiUrl, 'faq_en', loginInfo);
                const termEn = await Common.loadConfig(this.apiUrl, 'term_en', loginInfo);
                const privacyPolicyEn = await Common.loadConfig(this.apiUrl, 'privacy_policy_en', loginInfo);

                this.form.aboutUsEn = aboutUsEn;
                this.form.shippingInfoEn = shippingInfoEn;
                this.form.faqEn = faqEn;
                this.form.termEn = termEn;
                this.form.privacyPolicyEn = privacyPolicyEn;
            } catch (err) {
                console.log(err);
            }
        },
        async updateSettings() {
            try {
                const loginInfo = Common.getLoginInfo();

                //Chinese version
                await Common.updateConfig(this.apiUrl, 'about_us_zh', this.form.aboutUsZh, loginInfo);
                await Common.updateConfig(this.apiUrl, 'shipping_info_zh', this.form.shippingInfoZh, loginInfo);
                await Common.updateConfig(this.apiUrl, 'faq_zh', this.form.faqZh, loginInfo);
                await Common.updateConfig(this.apiUrl, 'term_zh', this.form.termZh, loginInfo);
                await Common.updateConfig(this.apiUrl, 'privacy_policy_zh', this.form.privacyPolicyZh, loginInfo);

                //English version
                await Common.updateConfig(this.apiUrl, 'about_us_en', this.form.aboutUsEn, loginInfo);
                await Common.updateConfig(this.apiUrl, 'shipping_info_en', this.form.shippingInfoEn, loginInfo);
                await Common.updateConfig(this.apiUrl, 'faq_en', this.form.faqEn, loginInfo);
                await Common.updateConfig(this.apiUrl, 'term_en', this.form.termEn, loginInfo);
                await Common.updateConfig(this.apiUrl, 'privacy_policy_en', this.form.privacyPolicyEn, loginInfo);

                this.$message({
                    message: '已成功更新商店資料',
                    type: 'success'
                });
            } catch (err) {
                console.log(err);
            }
        },
    },
    computed: mapState({
        apiUrl: state => state.apiUrl,
    }),
}
</script>

<style lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}

.shop-settings {
    .form-content {
        padding-left: 20px;

        .shop-image-wrapper {
            display: flex;

            .shop-logo {
                margin-right: 50px;
            }
        }

        .el-form-item__label {
            float: none;
        }

        .update-button {
            .el-form-item__content {
                text-align: right;
            }
        }
    }
}
</style>